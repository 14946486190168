import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanLoad {

  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.storageService.getToken().then((token: any) => {
      console.log(token);
      if (!token.value) {
        this.router.navigateByUrl('/login');
        return false;
      } else {
        return true;
      }
    });
  }

}
