import { InjectionToken } from '@angular/core';

import { environment } from '../environments/environment';
import { AppConfig } from './models/config.model';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export const APPCONFIG: AppConfig = {
    // QC_ENDPOINT
    authUser: environment.authQC +'pos/login',
    sendRegistrationOTP: environment.endpointQC + 'otp/request',
    validateRegistrationOTP: environment.endpointQC + 'otp/validate',
    registerMember: environment.endpointQC + 'persons/',
    searchMember: environment.endpointQC + 'persons/search',
    searchMemberExtra: environment.endpointQC + 'persons/search/extended-response',
    createTransaction: environment.endpointQC + 'transactions/loyalty',
    getStores: environment.endpointQC + 'stores',
    getCouponSets: environment.endpointQC + 'couponsets/search',
    updateCoupon: environment.endpointQC + 'coupons/',
    searchCoupons: environment.endpointQC + 'coupons/search',

    // CUSTOM LIQUID CONFIG VARIABLES
    country: 'gr',
    schemaCode: '0000'
};

export class AppConfigModule { }
