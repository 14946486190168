import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberExistService } from 'src/app/services/member/member-exist/member-exist.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input() token: boolean;

  constructor(
    private storageService: StorageService,
    public router: Router,
    private memberExistService: MemberExistService
  ) { }

  ngOnInit() {
    console.log(this.token);
  }

  onLogout() {
    this.storageService.removeToken().then(() => {
      this.memberExistService.checkMemberExists();
      this.router.navigateByUrl('/login');
    });
  }

}
