import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app-config.module';
import { AppConfig } from 'src/app/models/config.model';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RedemptionService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) { }

  assignCoupon(json: any, cQCCode: string, mQCcode: string, token: string)  {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', token);
    return this.http.post(
      this.config.registerMember + cQCCode + '/loyalty-membership/' + mQCcode + '/coupons/multi-actions',
      JSON.stringify(json),
      {headers, withCredentials: true, observe: 'response'}
    ).pipe(
      map(
        response => response
      ),
      catchError((err: any) => throwError(err)
    ));
  }
}
